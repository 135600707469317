.App{
  text-align: center;
}
  a {
  text-decoration: none !important;
 }
 :fullscreen, ::backdrop {
    background-color: rgba(255,255,255,100);
}
.gear_pump0, .gear_pump1, .gear_pump2, .gear_pump3, .gear_pump4, 
.gear_pump5, .gear_pump6, .gear_pump7, .gear_pump8, .gear_pump9
{ max-width: 100%; background-size: 100%; background-image: url('./assets/gear_pump/gear_pump_sprite.png'); }
 
.gear_pump0 { background-position: 0 0%; background-size: 100%; }
.gear_pump1 { background-position: 0 11.111111%; background-size: 100%; }
.gear_pump2 { background-position: 0 22.222222%; background-size: 100%; }
.gear_pump3 { background-position: 0 33.333333%; background-size: 100%; }
.gear_pump4 { background-position: 0 44.444444%; background-size: 100%; }
.gear_pump5 { background-position: 0 55.555556%; background-size: 100%; }
.gear_pump6 { background-position: 0 66.666667%; background-size: 100%; }
.gear_pump7 { background-position: 0 77.777778%; background-size: 100%; }
.gear_pump8 { background-position: 0 88.888889%; background-size: 100%; }
.gear_pump9 { background-position: 0 100%; background-size: 100%; }

.surub_arhimede0,
.surub_arhimede1,
.surub_arhimede2,
.surub_arhimede3,
.surub_arhimede4,
.surub_arhimede5,
.surub_arhimede6,
.surub_arhimede7,
.surub_arhimede8,
.surub_arhimede9,
.surub_arhimede10,
.surub_arhimede11,
.surub_arhimede12,
.surub_arhimede13,
.surub_arhimede14,
.surub_arhimede15,
.surub_arhimede16,
.surub_arhimede17,
.surub_arhimede18,
.surub_arhimede19,
.surub_arhimede20,
.surub_arhimede21,
.surub_arhimede22,
.surub_arhimede23,
.surub_arhimede24,
.surub_arhimede25,
.surub_arhimede26,
.surub_arhimede27,
.surub_arhimede28,
.surub_arhimede29,
.surub_arhimede30,
.surub_arhimede31,
.surub_arhimede32,
.surub_arhimede33,
.surub_arhimede34,
.surub_arhimede35,
.surub_arhimede36,
.surub_arhimede37,
.surub_arhimede38,
.surub_arhimede39,
.surub_arhimede40,
.surub_arhimede41,
.surub_arhimede42,
.surub_arhimede43,
.surub_arhimede44,
.surub_arhimede45,
.surub_arhimede46,
.surub_arhimede47,
.surub_arhimede48,
.surub_arhimede49 
{ max-width: 100%; background-size: 100%; background-image: url('./assets/surub_arhimede/surub_arhimede_sprite.png'); }
 
.surub_arhimede0 { background-position: 0 0.0%; background-size: 100%;}
.surub_arhimede1 { background-position: 0 2.040816%; background-size: 100%;}
.surub_arhimede2 { background-position: 0 4.081632%; background-size: 100%;}
.surub_arhimede3 { background-position: 0 6.122448%; background-size: 100%;}
.surub_arhimede4 { background-position: 0 8.163264%; background-size: 100%;}
.surub_arhimede5 { background-position: 0 10.20408%; background-size: 100%;}
.surub_arhimede6 { background-position: 0 12.244896%; background-size: 100%;}
.surub_arhimede7 { background-position: 0 14.285712%; background-size: 100%;}
.surub_arhimede8 { background-position: 0 16.326528%; background-size: 100%;}
.surub_arhimede9 { background-position: 0 18.367344%; background-size: 100%;}
.surub_arhimede10 { background-position: 0 20.40816%; background-size: 100%;}
.surub_arhimede11 { background-position: 0 22.448976%; background-size: 100%;}
.surub_arhimede12 { background-position: 0 24.489792%; background-size: 100%;}
.surub_arhimede13 { background-position: 0 26.530608%; background-size: 100%;}
.surub_arhimede14 { background-position: 0 28.571424%; background-size: 100%;}
.surub_arhimede15 { background-position: 0 30.61224%; background-size: 100%;}
.surub_arhimede16 { background-position: 0 32.653056%; background-size: 100%;}
.surub_arhimede17 { background-position: 0 34.693872%; background-size: 100%;}
.surub_arhimede18 { background-position: 0 36.734688%; background-size: 100%;}
.surub_arhimede19 { background-position: 0 38.775504%; background-size: 100%;}
.surub_arhimede20 { background-position: 0 40.81632%; background-size: 100%;}
.surub_arhimede21 { background-position: 0 42.857136%; background-size: 100%;}
.surub_arhimede22 { background-position: 0 44.897952%; background-size: 100%;}
.surub_arhimede23 { background-position: 0 46.938767999999996%; background-size: 100%;}
.surub_arhimede24 { background-position: 0 48.979584%; background-size: 100%;}
.surub_arhimede25 { background-position: 0 51.0204%; background-size: 100%;}
.surub_arhimede26 { background-position: 0 53.061216%; background-size: 100%;}
.surub_arhimede27 { background-position: 0 55.102032%; background-size: 100%;}
.surub_arhimede28 { background-position: 0 57.142848%; background-size: 100%;}
.surub_arhimede29 { background-position: 0 59.183664%; background-size: 100%;}
.surub_arhimede30 { background-position: 0 61.22448%; background-size: 100%;}
.surub_arhimede31 { background-position: 0 63.265296%; background-size: 100%;}
.surub_arhimede32 { background-position: 0 65.306112%; background-size: 100%;}
.surub_arhimede33 { background-position: 0 67.346928%; background-size: 100%;}
.surub_arhimede34 { background-position: 0 69.387744%; background-size: 100%;}
.surub_arhimede35 { background-position: 0 71.42856%; background-size: 100%;}
.surub_arhimede36 { background-position: 0 73.469376%; background-size: 100%;}
.surub_arhimede37 { background-position: 0 75.510192%; background-size: 100%;}
.surub_arhimede38 { background-position: 0 77.551008%; background-size: 100%;}
.surub_arhimede39 { background-position: 0 79.591824%; background-size: 100%;}
.surub_arhimede40 { background-position: 0 81.63264%; background-size: 100%;}
.surub_arhimede41 { background-position: 0 83.673456%; background-size: 100%;}
.surub_arhimede42 { background-position: 0 85.714272%; background-size: 100%;}
.surub_arhimede43 { background-position: 0 87.755088%; background-size: 100%;}
.surub_arhimede44 { background-position: 0 89.795904%; background-size: 100%;}
.surub_arhimede45 { background-position: 0 91.83672%; background-size: 100%;}
.surub_arhimede46 { background-position: 0 93.87753599999999%; background-size: 100%;}
.surub_arhimede47 { background-position: 0 95.918352%; background-size: 100%;}
.surub_arhimede48 { background-position: 0 97.959168%; background-size: 100%;}
.surub_arhimede49 { background-position: 0 99.999984%; background-size: 100%;}


.peristaltic_pump0,
.peristaltic_pump1,
.peristaltic_pump2,
.peristaltic_pump3,
.peristaltic_pump4,
.peristaltic_pump5,
.peristaltic_pump6,
.peristaltic_pump7,
.peristaltic_pump8,
.peristaltic_pump9,
.peristaltic_pump10,
.peristaltic_pump11,
.peristaltic_pump12,
.peristaltic_pump13,
.peristaltic_pump14,
.peristaltic_pump15,
.peristaltic_pump16,
.peristaltic_pump17,
.peristaltic_pump18,
.peristaltic_pump19,
.peristaltic_pump20,
.peristaltic_pump21,
.peristaltic_pump22,
.peristaltic_pump23,
.peristaltic_pump24,
.peristaltic_pump25,
.peristaltic_pump26
{ max-width: 100%; background-size: 100%; background-image: url('./assets/peristaltic_pump/peristaltic_pump_sprite.png'); }
.peristaltic_pump0 { background-position: 0 0.0%; background-size: 100%;}
.peristaltic_pump1 { background-position: 0 3.846154%; background-size: 100%;}
.peristaltic_pump2 { background-position: 0 7.692308%; background-size: 100%;}
.peristaltic_pump3 { background-position: 0 11.538461999999999%; background-size: 100%;}
.peristaltic_pump4 { background-position: 0 15.384616%; background-size: 100%;}
.peristaltic_pump5 { background-position: 0 19.23077%; background-size: 100%;}
.peristaltic_pump6 { background-position: 0 23.076923999999998%; background-size: 100%;}
.peristaltic_pump7 { background-position: 0 26.923078%; background-size: 100%;}
.peristaltic_pump8 { background-position: 0 30.769232%; background-size: 100%;}
.peristaltic_pump9 { background-position: 0 34.615386%; background-size: 100%;}
.peristaltic_pump10 { background-position: 0 38.46154%; background-size: 100%;}
.peristaltic_pump11 { background-position: 0 42.307694%; background-size: 100%;}
.peristaltic_pump12 { background-position: 0 46.153847999999996%; background-size: 100%;}
.peristaltic_pump13 { background-position: 0 50.000001999999995%; background-size: 100%;}
.peristaltic_pump14 { background-position: 0 53.846156%; background-size: 100%;}
.peristaltic_pump15 { background-position: 0 57.69231%; background-size: 100%;}
.peristaltic_pump16 { background-position: 0 61.538464%; background-size: 100%;}
.peristaltic_pump17 { background-position: 0 65.384618%; background-size: 100%;}
.peristaltic_pump18 { background-position: 0 69.230772%; background-size: 100%;}
.peristaltic_pump19 { background-position: 0 73.076926%; background-size: 100%;}
.peristaltic_pump20 { background-position: 0 76.92308%; background-size: 100%;}
.peristaltic_pump21 { background-position: 0 80.769234%; background-size: 100%;}
.peristaltic_pump22 { background-position: 0 84.615388%; background-size: 100%;}
.peristaltic_pump23 { background-position: 0 88.461542%; background-size: 100%;}
.peristaltic_pump24 { background-position: 0 92.30769599999999%; background-size: 100%;}
.peristaltic_pump25 { background-position: 0 96.15384999999999%; background-size: 100%;}
.peristaltic_pump26 { background-position: 0 100.00000399999999%; background-size: 100%;}


.pompa_cu_angrenaje_interioare0, .pompa_cu_angrenaje_interioare1, .pompa_cu_angrenaje_interioare10, .pompa_cu_angrenaje_interioare11, .pompa_cu_angrenaje_interioare12, 
.pompa_cu_angrenaje_interioare13, .pompa_cu_angrenaje_interioare14, .pompa_cu_angrenaje_interioare15, .pompa_cu_angrenaje_interioare16, .pompa_cu_angrenaje_interioare17, 
.pompa_cu_angrenaje_interioare2, .pompa_cu_angrenaje_interioare3, .pompa_cu_angrenaje_interioare4, .pompa_cu_angrenaje_interioare5, .pompa_cu_angrenaje_interioare6, 
.pompa_cu_angrenaje_interioare7, .pompa_cu_angrenaje_interioare8, .pompa_cu_angrenaje_interioare9
{ max-width: 100%; background-size: 100%; background-image: url('./assets/pompa_cu_angrenaje_interioare/pompa_cu_angrenaje_interioare_sprite.png'); }
 
.pompa_cu_angrenaje_interioare0 { background-position: 0 0%; background-size: 100%; }
.pompa_cu_angrenaje_interioare1 { background-position: 0 5.882353%; background-size: 100%; }
.pompa_cu_angrenaje_interioare10 { background-position: 0 11.764706%; background-size: 100%; }
.pompa_cu_angrenaje_interioare11 { background-position: 0 17.647059%; background-size: 100%; }
.pompa_cu_angrenaje_interioare12 { background-position: 0 23.529412%; background-size: 100%; }
.pompa_cu_angrenaje_interioare13 { background-position: 0 29.411765%; background-size: 100%; }
.pompa_cu_angrenaje_interioare14 { background-position: 0 35.294118%; background-size: 100%; }
.pompa_cu_angrenaje_interioare15 { background-position: 0 41.176471%; background-size: 100%; }
.pompa_cu_angrenaje_interioare16 { background-position: 0 47.058824%; background-size: 100%; }
.pompa_cu_angrenaje_interioare17 { background-position: 0 52.941176%; background-size: 100%; }
.pompa_cu_angrenaje_interioare2 { background-position: 0 58.823529%; background-size: 100%; }
.pompa_cu_angrenaje_interioare3 { background-position: 0 64.705882%; background-size: 100%; }
.pompa_cu_angrenaje_interioare4 { background-position: 0 70.588235%; background-size: 100%; }
.pompa_cu_angrenaje_interioare5 { background-position: 0 76.470588%; background-size: 100%; }
.pompa_cu_angrenaje_interioare6 { background-position: 0 82.352941%; background-size: 100%; }
.pompa_cu_angrenaje_interioare7 { background-position: 0 88.235294%; background-size: 100%; }
.pompa_cu_angrenaje_interioare8 { background-position: 0 94.117647%; background-size: 100%; }
.pompa_cu_angrenaje_interioare9 { background-position: 0 100%; background-size: 100%; }



.pompa_cu_pistonase_axiale0,
.pompa_cu_pistonase_axiale1,
.pompa_cu_pistonase_axiale2,
.pompa_cu_pistonase_axiale3,
.pompa_cu_pistonase_axiale4,
.pompa_cu_pistonase_axiale5,
.pompa_cu_pistonase_axiale6,
.pompa_cu_pistonase_axiale7,
.pompa_cu_pistonase_axiale8,
.pompa_cu_pistonase_axiale9,
.pompa_cu_pistonase_axiale10,
.pompa_cu_pistonase_axiale11,
.pompa_cu_pistonase_axiale12,
.pompa_cu_pistonase_axiale13,
.pompa_cu_pistonase_axiale14,
.pompa_cu_pistonase_axiale15,
.pompa_cu_pistonase_axiale16,
.pompa_cu_pistonase_axiale17,
.pompa_cu_pistonase_axiale18,
.pompa_cu_pistonase_axiale19,
.pompa_cu_pistonase_axiale20,
.pompa_cu_pistonase_axiale21,
.pompa_cu_pistonase_axiale22,
.pompa_cu_pistonase_axiale23,
.pompa_cu_pistonase_axiale24,
.pompa_cu_pistonase_axiale25,
.pompa_cu_pistonase_axiale26,
.pompa_cu_pistonase_axiale27,
.pompa_cu_pistonase_axiale28,
.pompa_cu_pistonase_axiale29,
.pompa_cu_pistonase_axiale30,
.pompa_cu_pistonase_axiale31,
.pompa_cu_pistonase_axiale32,
.pompa_cu_pistonase_axiale33,
.pompa_cu_pistonase_axiale34,
.pompa_cu_pistonase_axiale35
{ max-width: 100%; background-size: 100%; background-image: url('./assets/pompa_cu_pistonase_axiale/pompa_cu_pistonase_axiale_sprite.png'); }
.pompa_cu_pistonase_axiale0 { background-position: 0 0.0%; background-size: 100%;}
.pompa_cu_pistonase_axiale1 { background-position: 0 2.857143%; background-size: 100%;}
.pompa_cu_pistonase_axiale2 { background-position: 0 5.714286%; background-size: 100%;}
.pompa_cu_pistonase_axiale3 { background-position: 0 8.571429%; background-size: 100%;}
.pompa_cu_pistonase_axiale4 { background-position: 0 11.428572%; background-size: 100%;}
.pompa_cu_pistonase_axiale5 { background-position: 0 14.285715000000001%; background-size: 100%;}
.pompa_cu_pistonase_axiale6 { background-position: 0 17.142858%; background-size: 100%;}
.pompa_cu_pistonase_axiale7 { background-position: 0 20.000001%; background-size: 100%;}
.pompa_cu_pistonase_axiale8 { background-position: 0 22.857144%; background-size: 100%;}
.pompa_cu_pistonase_axiale9 { background-position: 0 25.714287000000002%; background-size: 100%;}
.pompa_cu_pistonase_axiale10 { background-position: 0 28.571430000000003%; background-size: 100%;}
.pompa_cu_pistonase_axiale11 { background-position: 0 31.428573000000004%; background-size: 100%;}
.pompa_cu_pistonase_axiale12 { background-position: 0 34.285716%; background-size: 100%;}
.pompa_cu_pistonase_axiale13 { background-position: 0 37.142859%; background-size: 100%;}
.pompa_cu_pistonase_axiale14 { background-position: 0 40.000002%; background-size: 100%;}
.pompa_cu_pistonase_axiale15 { background-position: 0 42.857145%; background-size: 100%;}
.pompa_cu_pistonase_axiale16 { background-position: 0 45.714288%; background-size: 100%;}
.pompa_cu_pistonase_axiale17 { background-position: 0 48.571431000000004%; background-size: 100%;}
.pompa_cu_pistonase_axiale18 { background-position: 0 51.428574000000005%; background-size: 100%;}
.pompa_cu_pistonase_axiale19 { background-position: 0 54.285717000000005%; background-size: 100%;}
.pompa_cu_pistonase_axiale20 { background-position: 0 57.142860000000006%; background-size: 100%;}
.pompa_cu_pistonase_axiale21 { background-position: 0 60.00000300000001%; background-size: 100%;}
.pompa_cu_pistonase_axiale22 { background-position: 0 62.85714600000001%; background-size: 100%;}
.pompa_cu_pistonase_axiale23 { background-position: 0 65.71428900000001%; background-size: 100%;}
.pompa_cu_pistonase_axiale24 { background-position: 0 68.571432%; background-size: 100%;}
.pompa_cu_pistonase_axiale25 { background-position: 0 71.42857500000001%; background-size: 100%;}
.pompa_cu_pistonase_axiale26 { background-position: 0 74.285718%; background-size: 100%;}
.pompa_cu_pistonase_axiale27 { background-position: 0 77.14286100000001%; background-size: 100%;}
.pompa_cu_pistonase_axiale28 { background-position: 0 80.000004%; background-size: 100%;}
.pompa_cu_pistonase_axiale29 { background-position: 0 82.85714700000001%; background-size: 100%;}
.pompa_cu_pistonase_axiale30 { background-position: 0 85.71429%; background-size: 100%;}
.pompa_cu_pistonase_axiale31 { background-position: 0 88.57143300000001%; background-size: 100%;}
.pompa_cu_pistonase_axiale32 { background-position: 0 91.428576%; background-size: 100%;}
.pompa_cu_pistonase_axiale33 { background-position: 0 94.285719%; background-size: 100%;}
.pompa_cu_pistonase_axiale34 { background-position: 0 97.14286200000001%; background-size: 100%;}
.pompa_cu_pistonase_axiale35 { background-position: 0 100.000005%; background-size: 100%;}



